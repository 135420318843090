import { Currency, Token } from "@uniswap/sdk-core";
import React, {
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactGA from "react-ga";
import { t, Trans } from "@lingui/macro";
import { FixedSizeList } from "react-window";
import { Text } from "rebass";
import { ExtendedEther } from "../../constants/tokens";
import { useActiveWeb3React } from "../../hooks/web3";
import {
  useAllTokens,
  useToken,
  useIsUserAddedToken,
  useSearchInactiveTokenLists,
} from "../../hooks/Tokens";
import { CloseIcon, TYPE, ButtonText, IconWrapper } from "../../theme";
import { isAddress } from "../../utils";
import Column from "../Column";
import Row, { RowBetween, RowFixed } from "../Row";
import CommonBases from "./CommonBases";
import CurrencyList from "./CurrencyList";
import { filterTokens, useSortedTokensByQuery } from "./filtering";
import { useTokenComparator } from "./sorting";
import { PaddedColumn, SearchInput, Separator } from "./styleds";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components/macro";
import useToggle from "hooks/useToggle";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import useTheme from "hooks/useTheme";
import ImportRow from "./ImportRow";
import { Edit } from "react-feather";
import useDebounce from "hooks/useDebounce";
import { listPair, getTokens } from "service/liquidity";

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`;

const Footer = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: ${({ theme }) => theme.bg1};
  border-top: 1px solid ${({ theme }) => theme.bg2};
`;

interface CurrencySearchProps {
  isOpen: boolean;
  onDismiss: () => void;
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency) => void;
  otherSelectedCurrency?: Currency | null;
  showCommonBases?: boolean;
  showManageView: () => void;
  showImportView: () => void;
  setImportToken: (token: Token) => void;
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  onDismiss,
  isOpen,
  showManageView,
  showImportView,
  setImportToken,
}: CurrencySearchProps) {
  const { chainId } = useActiveWeb3React();
  const theme = useTheme();

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebounce(searchQuery, 200);

  const [invertSearchOrder] = useState<boolean>(false);

  const allTokens = useAllTokens();

  // if they input an address, use it
  const isAddressSearch = isAddress(debouncedQuery);

  const searchToken = useToken(debouncedQuery);

  const searchTokenIsAdded = useIsUserAddedToken(searchToken);


  useEffect(() => {
    if (isAddressSearch) {
      ReactGA.event({
        category: "Currency Select",
        action: "Search by address",
        label: isAddressSearch,
      });
    }
  }, [isAddressSearch]);

  const tokenComparator = useTokenComparator(invertSearchOrder);
  // console.log('token comparator', tokenComparator);

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery);
  }, [allTokens, debouncedQuery]);
  // console.log('dilteredTokens--', filteredTokens);

  const sortedTokens: Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator);
  }, [filteredTokens, tokenComparator]);
  // console.log('dortedTokens', sortedTokens);

  let filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery); // filter here to display
  // only selected currencies
  // console.log('dortedTokens',filteredSortedTokens);


  const [addressToFilter, setAddressToFilter] = useState<any[]>([]);

  const handleListPair = async () => {
    try {
      const { data } = await getTokens();
      console.log('get all tokens', data);
      if (data.length > 0) {
        // Create a set to store unique addresses
        const uniqueAddresses = new Set();
        data.forEach((item) => {
          uniqueAddresses.add(item.address);
        });
        // Convert the set to an array and store in state
        setAddressToFilter([...uniqueAddresses]);
      } else {
        setAddressToFilter([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleListPair();
  }, []);

  // const res = filteredSortedTokens.map((token) => (token as any).list.tokens);
  // const currencyNames = filteredSortedTokens.map((token: any) => ({
  //   address: token._checksummedAddress,
  //   name: token.tokenInfo?.name || 'Unknown',
  // }));

  // console.log("res==>", currencyNames);

  // let mergedArray = res.flat(Infinity);
  // let filteredData: any = [];
  // mergedArray.forEach((element: any) => {
  //   // console.log("element==>", element);
  //   if (
  //     [
  //       "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
  //       "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  //       "0x6b175474e89094c44da98b954eedeac495271d0f",
  //       "0xdac17f958d2ee523a2206206994597c13d831ec7",
  //       "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
  //       "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  //       "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  //       "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  //       "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
  //       "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  //     ].includes(element.address)
  //   ) {
  //     filteredData.push(element);
  //   }
  //   console.log("filteredData==>", filteredData);
  // });
  // console.log(mergedArray, "hi");
  // const chunkSize = 100;
  // for (let i = 0; i < flatTokens.length; i += chunkSize) {
  //   const chunk = flatTokens.slice(i, i + chunkSize);
  //   console.log(`Processing chunk ${i / chunkSize + 1}`, chunk,"hi");
  // }
  // for (const token of flatTokens) {
  //   console.log(token,'hi'); // Process each token
  // }

  // const address = res.forEach((array) => {
  //   array.forEach((token: any) => {
  //     console.log(token,'hi');
  //   });
  // });

  // console.log('filteredSortedTokens', filteredSortedTokens);
  // console.log('addressToFilter', addressToFilter)



  let filteredToken = filteredSortedTokens.filter((token) =>
    addressToFilter.map((address) => address.toLowerCase()).includes(token.address.toLowerCase()));

  const ether = useMemo(() => chainId && ExtendedEther.onChain(chainId), [chainId]);

  let filteredSortedTokensWithETH: Currency[] = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim();
    if (s === "" || s === "e" || s === "et" || s === "eth") {
      return ether ? [ether, ...filteredSortedTokens] : filteredSortedTokens;
    }
    return filteredSortedTokens;
  }, [debouncedQuery, ether, filteredSortedTokens]);

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency);
      onDismiss();
    },
    [onDismiss, onCurrencySelect]
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery("");
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();

  const handleInput = useCallback((event) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === "eth" && ether) {
          handleCurrencySelect(ether);
        } else if (filteredSortedTokensWithETH.length > 0) {
          if (
            filteredSortedTokensWithETH[0].symbol?.toLowerCase() ===
              debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokensWithETH.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokensWithETH[0]);
          }
        }
      }
    },
    [debouncedQuery, ether, filteredSortedTokensWithETH, handleCurrencySelect]
  );

  // menu ui
  const [open, toggle] = useToggle(false);
  const node = useRef<HTMLDivElement>();
  useOnClickOutside(node, open ? toggle : undefined);

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    filteredTokens.length === 0 || (debouncedQuery.length > 2 && !isAddressSearch)
      ? debouncedQuery
      : undefined
  );

  console.log('debouncedQuery', debouncedQuery);

  return (
    <ContentWrapper>
      <PaddedColumn gap="16px">
        <RowBetween>
          <Text fontWeight={500} fontSize={16}>
            <Trans>Select a token</Trans>
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <Row>
          <SearchInput
            type="text"
            id="token-search-input"
            placeholder={t`Search name or paste address`}
            autoComplete="off"
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
            onKeyDown={handleEnter}
          />
        </Row>
        {/* {showCommonBases && (
          <CommonBases
            chainId={chainId}
            onSelect={handleCurrencySelect}
            selectedCurrency={selectedCurrency}
          />
        )} */}
      </PaddedColumn>
      <Separator />
      {searchToken && !searchTokenIsAdded ? (
        <Column style={{ padding: "20px 0", height: "100%" }}>
          <ImportRow
            token={searchToken}
            showImportView={showImportView}
            setImportToken={setImportToken}
          />
        </Column>
      ) : filteredSortedTokens?.length > 0 || filteredInactiveTokens?.length > 0 ? (
        <div style={{ flex: "1" }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <CurrencyList
                height={height}
                currencies={filteredToken}
                otherListTokens={filteredInactiveTokens}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                selectedCurrency={selectedCurrency}
                fixedListRef={fixedList}
                showImportView={showImportView}
                setImportToken={setImportToken}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <Column style={{ padding: "20px", height: "100%" }}>
          <TYPE.main color={theme.text3} textAlign="center" mb="20px">
            <Trans>No results found.</Trans>
          </TYPE.main>
        </Column>
      )}
      {/* <Footer>
        <Row justify="center">
          <ButtonText onClick={showManageView} color={theme.white} className="list-token-manage-button">
            <RowFixed>
              <IconWrapper size="16px" marginRight="6px">
                <Edit/>
              </IconWrapper>
              <TYPE.main color={theme.primaryText1}>
                <Trans>Manage Token Lists</Trans>
              </TYPE.main>
            </RowFixed>
          </ButtonText>
        </Row>
      </Footer> */}
    </ContentWrapper>
  );
}
