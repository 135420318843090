import { useEffect, useRef, memo } from "react";

function TradingViewWidget({ output }) {
  const container = useRef<HTMLDivElement | null>(null);
  console.log(output?.wrapped?.symbol);
  useEffect(() => {
    if (container.current) {
      container.current.innerHTML = ""; // Clear any previous chart

      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;

      // Correct way to use template literals in innerHTML
      script.innerHTML = `
            {
              "autosize": true,
               "symbol": "COINBASE:${output?.wrapped?.symbol}USD",
              "interval": "D",
              "timezone": "Etc/UTC",
              "theme": "dark",
              "style": "1",
              "locale": "en",
              "backgroundColor": "#062030",
            "allow_symbol_change": true,
            "hide_top_toolbar": true,
              "calendar": false,
              "support_host": "https://www.tradingview.com"
            }`;

      container.current.appendChild(script);
    }
  }, [output]);

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
}

export default memo(TradingViewWidget);
