import React, { CSSProperties, useEffect, useState } from "react";
import { Token } from "@uniswap/sdk-core";
import { AutoRow, RowFixed } from "components/Row";
import { AutoColumn } from "components/Column";
import CurrencyLogo from "components/CurrencyLogo";
import { TYPE } from "theme";
import ListLogo from "components/ListLogo";
import useTheme from "hooks/useTheme";
import { ButtonPrimary } from "components/Button";
import styled from "styled-components/macro";
import { useIsUserAddedToken, useIsTokenActive } from "hooks/Tokens";
import { CheckCircle } from "react-feather";
import { WrappedTokenInfo } from "../../state/lists/wrappedTokenInfo";
import { Trans } from "@lingui/macro";
import { getTokens, listPair } from "service/liquidity";
import { isAddress } from "utils";

const TokenSection = styled.div<{ dim?: boolean }>`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto;
  grid-gap: 16px;
  align-items: center;

  opacity: ${({ dim }) => (dim ? "0.4" : "1")};
`;

const CheckIcon = styled(CheckCircle)`
  height: 16px;
  width: 16px;
  margin-right: 6px;
  stroke: ${({ theme }) => theme.green1};
`;

const NameOverflow = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  font-size: 12px;
`;

export default function ImportRow({
  token,
  style,
  dim,
  showImportView,
  setImportToken,
}: {
  token: Token;
  style?: CSSProperties;
  dim?: boolean;
  showImportView: () => void;
  setImportToken: (token: Token) => void;
}) {
  const theme = useTheme();

  // check if already active on list or local storage tokens
  const isAdded = useIsUserAddedToken(token);
  const isActive = useIsTokenActive(token);

  const list = token instanceof WrappedTokenInfo ? token.list : undefined;

  const [addressToFilter, setAddressToFilter] = useState<any[]>([]);
  const [isPairMatched, setIsPairMatched] = useState(false);

  const handleListPair = async () => {
    try {
      const { data } = await getTokens();
      if (data.length > 0) {
        // Create a set to store unique addresses
        const uniqueAddresses = new Set();

        // Add baseTokenAddress and quoteTokenAddress to the set
        data.forEach((item) => {
          uniqueAddresses.add(item.address);
          // uniqueAddresses.add(item.quoteTokenAddress);
        });

        // Convert the set to an array and store in state
        setAddressToFilter([...uniqueAddresses]);
      } else {
        setAddressToFilter([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const isAddressSearch = isAddress(token?.address);
  const checkAddressPresence = () => {
    if (!isAddressSearch) return;
    const listAddresses = list?.tokens?.map((item) => item.address.toLowerCase()) || [];
    const tokenAddress = token?.address?.toLowerCase();

    let isPresent;
    if (listAddresses.length > 0) {
      isPresent = listAddresses.includes(tokenAddress) && addressToFilter.includes(tokenAddress);
      setIsPairMatched(isPresent);
      return;
    } else {
      isPresent = addressToFilter.includes(tokenAddress);
      setIsPairMatched(isPresent);
    }
  };

  useEffect(() => {
    if (addressToFilter.length > 0) {
      checkAddressPresence();
    }
  }, [addressToFilter, token, list]);

  useEffect(() => {
    handleListPair();
  }, []);

  if (!isPairMatched&&isAddressSearch) {
    return (
      <TYPE.main color={theme.text3} textAlign="center" mb="20px">
        <Trans>
          Token not supported.
          <br />
          We are curated dex, to get it supported, get in touch with us.
        </Trans>
      </TYPE.main>
    );
  }

  return (
    <TokenSection style={style}>
      <CurrencyLogo currency={token} size={"24px"} style={{ opacity: dim ? "0.6" : "1" }} />
      <AutoColumn gap="4px" style={{ opacity: dim ? "0.6" : "1" }}>
        <AutoRow>
          <TYPE.body fontWeight={500}>{token.symbol}</TYPE.body>
          <TYPE.darkGray ml="8px" fontWeight={300}>
            <NameOverflow title={token.name}>{token.name}</NameOverflow>
          </TYPE.darkGray>
        </AutoRow>
        {list && list.logoURI && (
          <RowFixed>
            <TYPE.small mr="4px" color={theme.text3}>
              <Trans>via {list.name} </Trans>
            </TYPE.small>
            <ListLogo logoURI={list.logoURI} size="12px" />
          </RowFixed>
        )}
      </AutoColumn>
      {!isActive && !isAdded ? (
        <ButtonPrimary
          width="fit-content"
          padding="6px 12px"
          fontWeight={500}
          fontSize="14px"
          onClick={() => {
            setImportToken && setImportToken(token);
            showImportView();
          }}
        >
          <Trans>Import</Trans>
        </ButtonPrimary>
      ) : (
        <RowFixed style={{ minWidth: "fit-content" }}>
          <CheckIcon />
          <TYPE.main color={theme.green1}>
            <Trans>Active</Trans>
          </TYPE.main>
        </RowFixed>
      )}
    </TokenSection>
  );
}
