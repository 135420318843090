import { computePairAddress, Pair } from '@uniswap/v2-sdk'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Interface } from '@ethersproject/abi'
import { V2_FACTORY_ADDRESSES, INIT_CODE_HASH } from '../constants/addresses'
import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { keccak256 } from '@ethersproject/solidity'
import { getAddress } from '@ethersproject/address'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function useV2Pairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const tokens = useMemo(
    () => currencies.map(([currencyA, currencyB]) => [currencyA?.wrapped, currencyB?.wrapped]),
    [currencies]
  )
console.log('tokens in useV2Pairs :', tokens);
console.log('V2_FACTORY_ADDRESSES in useV2Pairs :', V2_FACTORY_ADDRESSES[17000]);
  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA &&
          tokenB &&
          tokenA.chainId === tokenB.chainId &&
          !tokenA.equals(tokenB) &&
          V2_FACTORY_ADDRESSES[tokenA.chainId]
          ? computePairAddressCustom({ factoryAddress: V2_FACTORY_ADDRESSES[tokenA.chainId], tokenA, tokenB })
          : undefined
      }),
    [tokens]
  )
console.log('pairAddresses in useV2Pairs :', pairAddresses);
  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')
console.log('results in useV2Pairs :', results);
  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString())
        ),
      ]
    })
  }, [results, tokens])
}



export const computePairAddressCustom = ({
  factoryAddress,
  tokenA,
  tokenB
}: {
  factoryAddress: string
  tokenA: Token
  tokenB: Token
}): string => {
  // Sort tokens by address to ensure consistent pair addresses
  const [token0, token1] = tokenA.sortsBefore(tokenB) 
    ? [tokenA, tokenB] 
    : [tokenB, tokenA]

  // Create the salt by hashing the ordered token addresses
  const salt = keccak256(
    ['address', 'address'],
    [token0.address, token1.address]
  )

  // Compute CREATE2 address
  const addressBytes = keccak256(
    ['bytes1', 'address', 'bytes32', 'bytes32'],
    ['0xff', factoryAddress, salt, INIT_CODE_HASH]
  )

  // Extract the last 20 bytes (40 characters) to get the address
  // and convert to checksum address
  return getAddress('0x' + addressBytes.substring(26))
}


export function useV2Pair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const inputs: [[Currency | undefined, Currency | undefined]] = useMemo(() => [[tokenA, tokenB]], [tokenA, tokenB])
  return useV2Pairs(inputs)[0]
}
