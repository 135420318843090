import React, { useContext, useMemo, useState, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import JSBI from "jsbi";
import { Link } from "react-router-dom";
import { SwapPoolTabs } from "../../components/NavigationTabs";
import FullPositionCard from "../../components/PositionCard";
import { useTokenBalancesWithLoadingIndicator } from "../../state/wallet/hooks";
import { ExternalLink, TYPE, HideSmall } from "../../theme";
import { Text } from "rebass";
import Card from "../../components/Card";
import { RowBetween, RowFixed } from "../../components/Row";
import { ButtonPrimary, ButtonSecondary, ButtonOutlined } from "../../components/Button";
import { ChevronsRight } from "react-feather";

import { AutoColumn } from "../../components/Column";

import { useActiveWeb3React } from "../../hooks/web3";
import { useV2Pairs } from "../../hooks/useV2Pairs";
import { toV2LiquidityToken, useTrackedTokenPairs } from "../../state/user/hooks";
import { Dots } from "../../components/swap/styleds";
import { CardSection, DataCard, CardNoise, CardBGImage } from "../../components/earn/styled";
import { useStakingInfo } from "../../state/stake/hooks";
import { BIG_INT_ZERO } from "../../constants/misc";
import { Pair } from "@uniswap/v2-sdk";
import { Trans } from "@lingui/macro";
import LiquidityPool from "pages/LiquidityTable";
import { listPair } from "service/liquidity";

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`;

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #000000 0%, #000000 100%);
  overflow: hidden;
`;

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`;

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    // flex-direction: row-reverse;
    justify-content: space-between;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    flex-wrap: wrap;
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  border: 1px solid #fff;
  background-color: #fff;
  color: #04243c;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`;

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  border: 1px solid #fff;
  background-color: #fff;
  color: #04243c;
  width: fit-content;

  :hover {
    background-color: transparent;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`;

const EmptyProposals = styled.div`
  border: 1px solid rgb(0, 255, 194);
  // border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function Pool() {
  const theme = useContext(ThemeContext);
  const { account } = useActiveWeb3React();

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  );
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  );

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan("0")
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  );

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens));
  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);

  const allV2PairsWithLiquidity = v2Pairs
    .map(([, pair]) => pair)
    .filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo();
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  );
  const stakingPairs = useV2Pairs(
    stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens)
  );

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter(
          (stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address
        ).length === 0
    );
  });

  const [pairs, setPairs] = useState([]);
  const handleListPair = async () => {
    try {
      const { data } = await listPair("laxce");
      data ? setPairs(data) : setPairs([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleListPair();
  }, []);

  return (
    <>
      {/* <ResponsiveButtonPrimary
                    id="join-pool-button"
                    as={Link}
                    padding="6px 8px"
                    borderRadius="12px"
                    to="/add/v2/MATIC"
                    className="liquidity-btn"
                  >
                    <Text fontWeight={500} fontSize={16}>
                      <Trans>Add Liquidity</Trans>
                    </Text>
                  </ResponsiveButtonPrimary> */}
      <div className="liquidity-table w-100">
        <div className="container">
          <div className="table-responsive">
            <table className="table pair-table">
              <thead>
                <th className="text-center"><Trans>Symbol</Trans></th>
                <th><Trans>Pair</Trans></th>
                <th><Trans>Total Pool Tokens</Trans></th>
                <th><Trans>Token A</Trans></th>
                <th><Trans>Token B</Trans></th>
                <th><Trans>Pool Share</Trans></th>
                <th className="text-center"><Trans>Action</Trans></th>
              </thead>
              <tbody>
                {pairs.map((v2Pair, index) => (
                  <LiquidityPool
                    key={index}
                    pair={v2Pair}
                    index={index}
                    stakedBalance={stakingInfosWithBalance[index]?.stakedAmount}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

   <PageWrapper>
        <div className="swap-main">
          <SwapPoolTabs active={"pool"} />
          <VoteCard>
            <CardBGImage />
            <CardNoise />
            <CardSection>
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>
                    <Trans>Liquidity provider rewards</Trans>
                  </TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white fontSize={14}>
                    <Trans>
                      Liquidity providers earn a 0.3% fee on all trades proportional to their share
                      of the pool. Fees are added to the pool, accrue in real time and can be
                      claimed by withdrawing your liquidity.
                    </Trans>
                  </TYPE.white>
                </RowBetween>
                <ExternalLink
                  style={{ color: "white", textDecoration: "underline" }}
                  target="_blank"
                  href="#"
                >
                  <TYPE.white fontSize={14}>
                    <Trans>Read more about providing liquidity</Trans>
                  </TYPE.white>
                </ExternalLink>
              </AutoColumn>
            </CardSection>
            <CardBGImage />
            <CardNoise />
          </VoteCard>

          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="md" style={{ width: "100%" }}>
              <TitleRow style={{ marginTop: "1rem" }} padding={"0"}>
                <HideSmall>
                  <TYPE.mediumHeader style={{ marginTop: "0.5rem", justifySelf: "flex-start" }}>
                    <Trans>Your V2 liquidity</Trans>
                  </TYPE.mediumHeader>
                </HideSmall>
                <ButtonRow>
                  <ResponsiveButtonSecondary
                    as={Link}
                    padding="6px 8px"
                    to="/add/v2/MATIC"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <Trans>Create a pair</Trans>
                  </ResponsiveButtonSecondary>
                  <ResponsiveButtonPrimary
                    id="find-pool-button"
                    as={Link}
                    padding="6px 8px"
                    borderRadius="12px"
                    to="/pool/v2/find"
                    className="liquidity-btn"
                  >
                    <Text fontWeight={500} fontSize={16}>
                      <Trans>Import Pool</Trans>
                    </Text>
                  </ResponsiveButtonPrimary>
                  <ResponsiveButtonPrimary
                    id="join-pool-button"
                    as={Link}
                    padding="6px 8px"
                    borderRadius="12px"
                    to="/add/v2/MATIC"
                    className="liquidity-btn"
                  >
                    <Text fontWeight={500} fontSize={16}>
                      <Trans>Liquidity</Trans>
                    </Text>
                  </ResponsiveButtonPrimary>
                </ButtonRow>
              </TitleRow>

              {!account ? (
                <Card padding="40px">
                  <TYPE.body color={theme.white} textAlign="center">
                    <Trans>Connect to a wallet to view your liquidity.</Trans>
                  </TYPE.body>
                </Card>
              ) : v2IsLoading ? (
                <EmptyProposals>
                  <TYPE.body color={theme.text3} textAlign="center">
                    <Dots>
                      <Trans>Loading</Trans>
                    </Dots>
                  </TYPE.body>
                </EmptyProposals>
              ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                <>
                  <ButtonSecondary>
                    <RowBetween>
                      <Trans>
                        <ExternalLink href={"https://dexinfo.blockchainx.tech/account/" + account}>
                          Account analytics and accrued fees
                        </ExternalLink>
                        <span> ↗ </span>
                      </Trans>
                    </RowBetween>
                  </ButtonSecondary>
                  <div className="liquidity-table w-100"></div>
                  {v2PairsWithoutStakedAmount.map((v2Pair) => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                  {stakingPairs.map(
                    (stakingPair, i) =>
                      stakingPair[1] && ( // skip pairs that arent loaded
                        <FullPositionCard
                          key={stakingInfosWithBalance[i].stakingRewardAddress}
                          pair={stakingPair[1]}
                          stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                        />
                      )
                  )}
                  <RowFixed justify="center" style={{ width: "100%" }}>
                    <ButtonOutlined
                      as={Link}
                      to="/migrate/v2"
                      id="import-pool-link"
                      style={{
                        padding: "8px 16px",
                        margin: "0 4px",
                        borderRadius: "12px",
                        width: "fit-content",
                        fontSize: "14px",
                      }}
                    >
                      <ChevronsRight size={16} style={{ marginRight: "8px" }} />
                      <Trans>Migrate Liquidity to V3</Trans>
                    </ButtonOutlined>
                  </RowFixed>
                </>
              ) : (
                <EmptyProposals>
                  <TYPE.body color={theme.text5} textAlign="center">
                    <Trans>No liquidity found.</Trans>
                  </TYPE.body>
                </EmptyProposals>
              )}
            </AutoColumn>
          </AutoColumn>
        </div>
      </PageWrapper>
    </>
  );
}
