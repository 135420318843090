import { createReducer } from '@reduxjs/toolkit'
import { Field, replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'

export interface SwapState {
  readonly independentField: Field
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined
  }
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null
}

const initialState: SwapState = {
  independentField: Field.INPUT,
  typedValue: '',
  [Field.INPUT]: {
    currencyId: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  },
  [Field.OUTPUT]: {
    currencyId: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  },
  recipient: null,
}



export default createReducer<SwapState>(initialState, (builder) =>
  builder
    .addCase(
      replaceSwapState,
      (state, { payload: { typedValue, recipient, field, inputCurrencyId, outputCurrencyId } }) => {
        return {
          [Field.INPUT]: {
            currencyId: inputCurrencyId,
          },
          [Field.OUTPUT]: {
            currencyId: outputCurrencyId,
          },
          independentField: field,
          typedValue: typedValue,
          recipient,
        }
      }
    )
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [field]: { currencyId: currencyId },
          [otherField]: { currencyId: state[field].currencyId },
        }
      } else {
        // the normal case
        return {
          ...state,
          [field]: { currencyId: currencyId },
        }
      }
    })
    .addCase(switchCurrencies, (state) => {

      console.log('Before Switch:', {
        inputCurrency: state[Field.INPUT].currencyId,
        outputCurrency: state[Field.OUTPUT].currencyId,
      });
      const inputCurrencyId = state[Field.INPUT].currencyId ==="MATIC"? '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48':state[Field.INPUT].currencyId;
  const outputCurrencyId = state[Field.OUTPUT].currencyId===""?"0xdAC17F958D2ee523a2206206994597C13D831ec7":state[Field.OUTPUT].currencyId

  if (!inputCurrencyId || !outputCurrencyId) {
    // Handle the case when one or both currencies are empty
    console.warn('One or both currencies are empty');
    return state; // Do nothing if one of the currencies is missing
  }

  const newState = {
    ...state,
    independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
    [Field.INPUT]: { currencyId: outputCurrencyId },  // Switch input to output
    [Field.OUTPUT]: { currencyId: inputCurrencyId },  // Switch output to input
  };

  console.log('After Switch:', {
    inputCurrency: newState[Field.INPUT].currencyId,
    outputCurrency: newState[Field.OUTPUT].currencyId,
  });

  return newState;
      
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
)
