import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Toast animation
const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const ToastContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Toast = styled.div<{ type: string }>`
  min-width: 300px;
  padding: 15px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: ${slideIn} 0.3s ease forwards;

  background-color: ${({ type }) => {
    switch (type) {
      case "success":
        return "#4caf50"; // Green
      case "error":
        return "#f44336"; // Red
      case "warning":
        return "#ffc107"; // Yellow
      case "info":
        return "#2196f3"; // Blue
      default:
        return "#333"; // Default gray
    }
  }};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

// Icon styling
const Icon = styled.div`
  font-size: 20px;
`;

const ToastMessage = ({ type, message, onClose, autoCloseTime }: any) => {
  useEffect(() => {
    const timer = setTimeout(onClose, autoCloseTime || 3000); // Auto close after 3 seconds
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [onClose, autoCloseTime]);

  return (
    <Toast type={type}>
      <Icon>{getIcon(type)}</Icon>
      {message}
    </Toast>
  );
};

// Helper to get icons based on toast type
const getIcon = (type: string) => {
  switch (type) {
    case "success":
      return "✅";
    case "error":
      return "❗"; 
    case "warning":
      return "⚠️";
    case "info":
      return "ℹ️";
    default:
      return "ℹ️";
  }
};

export const ToastManager = ({ toasts, removeToast }: any) => {
  return (
    <ToastContainer>
      {toasts.length > 0 && (
        <ToastMessage
          type={toasts[0].type}
          message={toasts[0].message}
          autoCloseTime={toasts[0].autoCloseTime || 3000}
          onClose={() => removeToast(0)}
        />
      )}
    </ToastContainer>
  );
};
