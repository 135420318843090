import useScrollPosition from "@react-hook/window-scroll";
import React, { useState } from "react";
import { Text } from "rebass";
import { NavLink } from "react-router-dom";
import { darken } from "polished";
import { t, Trans } from "@lingui/macro";
import { Moon, Sun } from "react-feather";
import styled from "styled-components/macro";

import Logo from "../../assets/png/logo_white.png";
import LogoDark from "../../assets/png/logo_white.png";
import Laxcelogo from "../../assets/images/laxce-logo1.svg";
import Laxcelogo1 from "../../assets/images/laxce-logo.svg";
import Tokenlogo from "../../assets/images/token-logo.svg";
import { SupportedChainId } from "../../constants/chains";

import { useActiveWeb3React } from "../../hooks/web3";
import { useDarkModeManager } from "../../state/user/hooks";
import { useETHBalances } from "../../state/wallet/hooks";
import { CardNoise } from "../earn/styled";
import { TYPE, ExternalLink } from "../../theme";

import { YellowCard } from "../Card";
import Menu from "../Menu";

import Row, { RowFixed } from "../Row";
import Web3Status from "../Web3Status";
import ClaimModal from "../claim/ClaimModal";
import { useToggleSelfClaimModal, useShowClaimPopup } from "../../state/application/hooks";
import { useUserHasAvailableClaim } from "../../state/claim/hooks";
import { useUserHasSubmittedClaim } from "../../state/transactions/hooks";
import { Dots } from "../swap/styleds";
import Modal from "../Modal";
import UniBalanceContent from "./UniBalanceContent";
import { dynamicActivate } from "i18n";
import { useLingui } from "@lingui/react";

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: flex;
  // grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 16px 60px;
  z-index: 21;
  position: relative;

  /* Background slide effect on scroll. */
  // background-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 50%, ${theme.bg3} 50% )}}`}
  background-position: ${({ showBackground }) => (showBackground ? "0 -100%" : "0 0")};
  background-color: ${({ showBackground }) =>
    showBackground ? "rgb(8, 144, 162)" : "transferent"};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px
    ${({ theme, showBackground }) => (showBackground ? theme.bg2 : "transparent;")};
  transition: background-position 0.1s, box-shadow 0.1s;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: auto 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
  `}
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    // width: 100%;
    // max-width: 960px;
    padding: 1rem;
    // position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    // background-color: ${({ theme }) => theme.bg1};
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 50px;
    padding: 0px;
  `};
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    align-items: center;
  `};
`;

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  //  width: 100%;
  `};
`;

const HeaderLinks = styled(Row)`
  justify-self: center;
  // background-color: ${({ theme }) => theme.bg0};
  // border-radius: 10px;
  // border: 1px solid rgba(255, 255, 255, 0.40);
  // backdrop-filter: blur(50px);
  width: fit-content;
  // padding: 4px;
  // border-radius: 16px;
  display: flex;
  // grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg2)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`;

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`;

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`;

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

const NetworkCard = styled(YellowCard)`
  padding: 8px 12px;
  border-radius: 20px;
  // border: 1px solid #00FFC2;
  background: linear-gradient(180deg, #04243c 0%, #00787f 100%);
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`;

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  text-decoration: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`;

const UniIcon = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 15px;
  color: #062030;
  font-size: 26.891px;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  transition: transform 0.3s ease;
  text img {
    width: auto;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 22px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 0px;
  `};
`;

const activeClassName = "ACTIVE";

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #04243C;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 8px 12px;
  word-break: break-word;

  &.${activeClassName} {
    color: #04243C;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 8px 12px;
    border-bottom: 3px solid rgb(4, 36, 60);
    border-radius: 0px;
    background: transparent;

  :hover,
  :focus {
    color: #04243C;
  }
`;

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`;

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg2};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`;

const NETWORK_LABELS: { [chainId in SupportedChainId | number]: string } = {
  [SupportedChainId.MAINNET]: "Mainnet",
  [SupportedChainId.RINKEBY]: "Rinkeby",
  [SupportedChainId.ROPSTEN]: "Ropsten",
  [SupportedChainId.GOERLI]: "Görli",
  [SupportedChainId.KOVAN]: "Kovan",
  [SupportedChainId.MUMBAI]: "Mumbai",
  [SupportedChainId.AMOY]: "AMOY",
  [SupportedChainId.ARBITRUM_KOVAN]: "kArbitrum",
  [SupportedChainId.ARBITRUM_ONE]: "Arbitrum One",
  [SupportedChainId.HOLESKY]: "Holesky",
};

export default function Header() {
  const [toggle, setToggle] = useState(false);
  const { account, chainId } = useActiveWeb3React();
  const { i18n } = useLingui();

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? ""];
  // const [isDark] = useDarkModeManager()
  const [darkMode, toggleDarkMode] = useDarkModeManager();

  const toggleClaimModal = useToggleSelfClaimModal();

  const availableClaim: boolean = useUserHasAvailableClaim(account);

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined);

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false);
  const showClaimPopup = useShowClaimPopup();

  const scrollY = useScrollPosition();

  const handleToggle = () => {
    setToggle((preVal) => !preVal);
  };

  const [currentLocale, setCurrentLocale] = useState<"en-US" | "ar-SA">("en-US");

  const toggleLanguage = () => {
    const newLocale = currentLocale === "en-US" ? "ar-SA" : "en-US";
    dynamicActivate(newLocale)
      .then(() => {
        setCurrentLocale(newLocale);
        console.log(`Language switched to ${newLocale}`);
      })
      .catch((error) => {
        console.error(`Failed to switch language to ${newLocale}`, error);
      });
  };

  const networkLabel = chainId && NETWORK_LABELS[chainId] ? NETWORK_LABELS[chainId] : "";

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <HeaderRow>
        <Title href=".">
          <UniIcon className="header-logo">
            <img src={Laxcelogo} alt="logo" className="web-logo" />
            <img src={Laxcelogo1} alt="logo" className="mobile-logo" />
          </UniIcon>
        </Title>
      </HeaderRow>
      <HeaderLinks className="web-menu">
        <StyledNavLink id={`swap-nav-link`} to={"/swap"}>
          <Trans>Swap</Trans>
        </StyledNavLink>
    {/* <StyledNavLink
          id={`pool-nav-link`}
          to={"/pool"}
          isActive={(match, { pathname }) =>
            Boolean(match) ||
            pathname.startsWith("/add") ||
            pathname.startsWith("/remove") ||
            pathname.startsWith("/increase") ||
            pathname.startsWith("/find")
          }
        >
          <Trans>Liquidity</Trans>
        </StyledNavLink>  */}
        {/* <StyledExternalLink id={`stake-nav-link`} href={'https://dexinfo.blockchainx.tech/'}>
          <Trans>Charts</Trans>
          <sup>↗</sup>
        </StyledExternalLink> */}
      </HeaderLinks>
      <HeaderControls>
        <HeaderElement>
          <HideSmall>
            {chainId && chainId === SupportedChainId.MAINNET && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>{networkLabel==='Mainnet'?'Ethereum':networkLabel}</NetworkCard>
            )}
            {chainId && chainId !== SupportedChainId.MAINNET && NETWORK_LABELS[chainId] && ( 
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            )}
          </HideSmall>
          {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: "auto" }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? (
                    <Dots>
                      <Trans>Claiming UNI</Trans>
                    </Dots>
                  ) : (
                    <Trans>Claim UNI</Trans>
                  )}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          <AccountElement active={!!account} style={{ pointerEvents: "auto" }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                <Trans>{userEthBalance?.toSignificant(4)} ETH</Trans>
              </BalanceText>
            ) : null}
            {/* <img src={Tokenlogo} alt="logo" width={36} height={38} style={{marginRight:'10px'}} /> */}
            <Web3Status />
            <button className="language-btn" onClick={toggleLanguage}>
              {currentLocale === "en-US" ? "العربية" : "English"}
            </button>
          </AccountElement>
        </HeaderElement>

        <HeaderElementWrap>
          {/* <StyledMenuButton onClick={() => toggleDarkMode()}>
            {darkMode ? <Moon size={20} /> : <Sun size={20} />}
          </StyledMenuButton> */}
          <Menu />
          <button onClick={handleToggle} className="mobile-toggle-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 12H9M21 18H7M21 6H3"
                stroke="#04243C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          {toggle && (
            <ul className="mobile-menu-list">
              <li>
                <StyledNavLink className={"swap-menu"} id={`swap-nav-link`} to={"/swap"}>
                  <Trans>Swap</Trans>
                </StyledNavLink>
              </li>
              {/* <li>
                <StyledNavLink className={"swap-menu"} id={`pool-nav-link`} to={"/pool"}>
                  <Trans>Liquidity</Trans>
                </StyledNavLink>
              </li> */}
            </ul>
          )}
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  );
}
